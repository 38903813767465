@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #47a3f3;
  color: #fefefe;
}

html {
  min-width: 360px;
}

.prose .anchor {
  @apply absolute invisible no-underline;

  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  @apply visible;
}

.prose a {
  @apply transition-all decoration-blue-600 hover:decoration-blue-400 dark:decoration-blue-700 dark:hover:decoration-blue-400 underline underline-offset-2 decoration-[0.1em];
}

.prose .anchor:after {
  /* add anchor link icon */
  @apply text-neutral-300 dark:text-neutral-700;
  content: "#";
}

.prose *:hover > .anchor {
  @apply visible;
}

.prose pre {
  @apply bg-neutral-50 dark:bg-neutral-900 rounded-lg overflow-x-auto border border-neutral-200 dark:border-neutral-900;
}

.prose code {
  @apply px-1 py-0.5 rounded-lg;
}

.prose pre code {
  @apply p-0;
  border: initial;
  line-height: 1.5;
}

.prose code span {
  @apply font-medium;
}

.prose img {
  /* Don't apply styles to next/image */
  @apply m-0;
}

.prose h2,
h3,
h4 {
  @apply font-medium  !important;
}

.prose strong {
  @apply font-medium;
}

.prose > :first-child {
  /* Override removing top margin, causing layout shift */
  margin-top: 1.25em !important;
  margin-bottom: 1.25em !important;
}

pre::-webkit-scrollbar {
  display: none;
}

pre {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Remove Safari input shadow on mobile */
input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.prose .tweet a {
  text-decoration: inherit;
  font-weight: 500;
}

table {
  display: block;
  max-width: fit-content;
  overflow-x: auto;
  white-space: nowrap;
}

.prose .callout > p {
  margin: 0 !important;
}

.title {
  text-wrap: balance;
}
